<template>
  <div class="prodCard-item iq-border-radius-20 overflow-hidden mb-4 w-100">
    <div>
    <router-link :to="inFav ? `/stores/merchants/${productItem.store.category.slug}/store-profile/${productItem.store.url}/products/${productItem.id}` : {name:'productDetails', params:{productId: productItem.id}}">
      <p v-if="inFav" class="m-0 p-0 p-3 font-size-18 font-weight-bold text-primary">{{productItem.store.name}}</p>
        <div class="prodCard-image" :style="{ 'background-image': 'url(' + productItem.featured_image + ')' }">
          <div class="d-flex justify-content-end p-2" v-if="productItem.flash_sales.length > 0">
              <span class="discount-product">{{productItem.flash_sales ? productItem.flash_sales[productItem.flash_sales.length - 1].percentage : ''}}%</span>
          </div>
        </div>
        <div class="d-flex flex-column align-items-center mt-2">
          <p class="text-primary mb-0 font-size-20 font-weight-bold">{{productItem.name.length >= 25 ? productItem.name.slice(0, 25)+'....'  : productItem.name}}</p>
          <p class="font-size-18 px-2 text-muted mb-0">{{productItem.description.length >= 25 ? productItem.description.slice(0, 25)+'....'  : productItem.description}}</p>
          <div class="product-price-info d-flex justify-content-center align-items-center">
            <div class="product-price-info d-flex justify-content-center align-items-center" v-if="productItem.flash_sales.length > 0">
            <p class="price-offer font-size-16">{{productItem.price_info.final_price}}</p>
            <p class="text-primary font-size-22 font-weight-bold">{{productItem.price_info.final_price}}<span class="text-primary font-size-12 pr-1">ر.س</span></p>
            </div>
            <p v-else class="text-primary font-size-22 font-weight-bold">{{productItem.price_info.final_price}}<span class="text-primary font-size-12 pr-1">ر.س</span></p>
          </div>
        </div>
    </router-link>
    <div class="d-flex justify-content-between align-items-center">
      <b-button
          v-if="!check(productItem)"
          @click.prevent="addToCart(productItem)"
          variant="primary"
          class="d-flex justify-content-between bg-transparent border-0 border-black text-black font-bold product-card-button"
      >
        <span>{{ $t('marketplace.addToCart') }}</span>
        <i class="las la-shopping-cart ml-0 mr-2 font-size-18"></i>
      </b-button>
      <b-button
          v-else
          variant="outline-primary"
          class="d-flex justify-content-between font-bold product-card-button"
      >
        <span>{{ $t('marketplace.inCart') }}</span>
        <i class="las la-shopping-cart ml-0 mr-2 font-size-18"></i>
      </b-button>
      <b-button
          v-if="!checkFavourite(productItem)"
          @click.prevent="addToFavourite({...productItem, catId: $route.params.id, storeSlogan : $route.params.slogan})"
          class="product-card-heart-button">
        <i class="las la-heart"></i>
      </b-button>
      <b-button
          v-else
          @click.prevent="deleteProductFavourite(productItem)"
          class="product-in-favourite-button">
        <i class="las la-heart"></i>
      </b-button>
    </div>
    </div>
  </div>
</template>

<script>
// import { core } from '@/config/pluginInit'
import productFunctions from '@/mixins/productFunctions'
export default {
  props: {
    inFav: {
      default: false
    },
    productItem: {
      dafault: {}
    }
  },
  mixins: [productFunctions],
  data () {
    return {
      defaultImage: require('@/assets/images/ibbil/ibbil-cover.jpg'),
      title: 'جلد طبيعى جلد خروف',
      description: 'عبارة عن وصف التصنيع',
      price: '550',
      offer: '120.54',
      unit: 'ر.س',
      rating: 5,
      discount: 16
    }
  },
  computed: {
  },
  mounted () {
    // core.index()
  }
}
</script>

<style>
.prodCard-item{
  background-color: #FFFFFF;
  box-shadow: 0px 9px 20px #0000001a;
}
.prodCard-image{
  width: 100%;
  height: 280px;
  background-size: cover;
  background-position: center;
}
.prodCard-image img{
  max-height: 100%;
}
.product-card-button {
  border-radius: 10px 0px 19px 0px !important;
}
.product-card-heart-button {
  border: none !important;
  background-color: #EDEDED !important;
  border-radius: 0px 7px 0px 18px !important;
  box-shadow: 0px 9px 20px #0000001A !important;
}
.product-card-heart-button i{
  color: #FFFFFF;
  font-size: 18px;
  margin-left: 0px !important;
}
.product-in-favourite-button {
  border: none !important;
  background-color: #EDEDED !important;
  border-radius: 0px 7px 0px 18px !important;
  box-shadow: 0px 9px 20px #0000001A !important;
}
.product-in-favourite-button i{
  color: #D39D45;
  font-size: 18px;
  margin-left: 0px !important;
}
.discount-product {
  background: #D62020;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
}
.product-price-info .price-offer{
  margin-left: 12px;
  color: #EC5D61;
  text-decoration: line-through #EC5D61;
}

</style>
